
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-page-header {
        background-color: $placeholder-background;
        background-size: cover;
        text-align: center;
        background-image: var(--small-background-image);
        height: 84px;
        padding: $nu-spacer-2;

        @include breakpoint(medium) {
            background-image: var(--medium-background-image);
            height: 124px;
            padding: $nu-spacer-4 $nu-spacer-2;
        }

        &--no-image {
            background-color: transparent;
            background-image: none;
        }

        &--black {
            color: $nu-black;
        }

        &--white {
            color: $nu-white;
        }

        &--primary {
            color: $nu-primary;
        }

        &--secondary {
            color: $nu-secondary;
        }

        &__title {
            @include breakpoint(small) {
                @include heading-1;
            }
        }
    }
