
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-news-home {
        &__page-header {
            margin-bottom: $nu-spacer-6;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-8;
            }
        }

        &__related-articles {
            margin-bottom: $nu-spacer-6;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-5;
            }
        }

        &__related-article {
            margin-bottom: $nu-spacer-2;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-3;
            }
        }

        &__hr {
            @include horizontal-rule($nu-gray--medium);
        }
    }
